<template>
    <v-dialog v-model="dialog" width=900>
        <v-sheet>
            <v-toolbar color="orange" dark>
                <v-toolbar-title>
                    AVISO URGENTE
                </v-toolbar-title>
            </v-toolbar>
            <div class="ma-5">
                <div class="text-h5 font-weight-black">{{esteComunicado.titulo}}</div>
                <div
                    class="text-justify ma-3"
                    v-html="esteComunicado.nota">
                </div>

            </div>
        </v-sheet>

    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogModal: Boolean,
        esteComunicado:Object,
    },

    data:()=>({
        formValid:false,
    }),

    methods:{
        async guarda(){
            this.dialog=false
        },
        cancela(){
            this.dialog=false
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{

    },
}
</script>

<style>

</style>